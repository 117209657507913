import { Button, Grid, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../config";
import { DataGrid } from "@mui/x-data-grid";

const columns = [
  {
    field: "name",
    headerName: "NAME",
    width: 300, // renderCell will render the component
  },
  { field: "email", headerName: "EMAIL", width: 300 },
  {
    field: "action",
    headerName: "ACTION",
    width: 300,
    renderCell: (params) => (
      <Button
        color="inherit"
        size="small"
        variant="outlined"
        style={{ marginRight: 10 }}
        onClick={() => false}
      >
        REMOVE ADMIN
      </Button>
    ),
  },
];

const ManageStaff = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [adminData, setAdminData] = useState([]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await axios.get(BASE_URL + "admin/get_admins").then((res) => {
      if (res.data.status === 201 && res.data.message === "data_fetched.") {
        setAdminData(res.data.data);
      }
    });
  };
  const addNewStaff = async () => {
    if (name !== "" && email !== "" && password !== "") {
      await axios
        .post(BASE_URL + "admin/register_admin", {
          name,
          email,
          password,
        })
        .then((res) => {
          if (
            res.data.status === 201 &&
            res.data.message === "resgitration_successfull."
          ) {
            alert("Admin added successfully.");
            getData();
            setEmail("");
            setPassword("");
            setName("");
          }
        })
        .catch((err) => {
          setEmail("");
          setPassword("");
          setName("");
          alert(
            "Email Already exists or something went wrong. Please try again"
          );
        });
    } else {
      alert("All fields required.");
    }
  };
  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      style={{ marginRight: 10, marginLeft: 10 }}
    >
      <Grid style={{ marginLeft: 10, marginTop: 10 }}>
        <Grid container>
          <TextField
            value={name}
            style={{ marginRight: 10 }}
            required
            id="outlined-basic"
            label="Full Name"
            variant="outlined"
            onChange={(event) => setName(event.target.value)}
          />
          <TextField
            value={email}
            style={{ marginRight: 10 }}
            required
            id="outlined-basic"
            label="Email"
            variant="outlined"
            onChange={(event) => setEmail(event.target.value)}
          />
          <TextField
            value={password}
            style={{ marginRight: 10 }}
            required
            id="outlined-basic"
            label="Password"
            variant="outlined"
            onChange={(event) => setPassword(event.target.value)}
          />
          <Button
            size="small"
            variant="contained"
            style={{ marginLeft: 10, height: "auto" }}
            onClick={() => addNewStaff()}
          >
            Add
          </Button>
        </Grid>

        {/* // Table */}
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              sx={{
                // disable cell selection style
                ".MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                // pointer cursor on ALL rows
                "& .MuiDataGrid-row:hover": {
                  cursor: "pointer",
                },
              }}
              rows={adminData}
              // rows={rows}
              columns={columns}
              // onRowClick={(event) => activateThis(event)}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ManageStaff;
