import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid, Paper } from "@mui/material";
import styled from "@emotion/styled";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#efefef",
  padding: 10,
  textAlign: "center",
  color: "#000",
}));

export default function OddsAccordion({ marketOdds }) {
  return (
    <div>
      {marketOdds[0].bookmakers[0] &&
        marketOdds[0].bookmakers[0]?.bets.map((res) => {
          return (
            <Accordion key={res.id}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>{res.name}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={{ xs: 2 }} columns={{ xs: 4 }}>
                  {res.values.map((val, index) => {
                    return (
                      <Grid item xs={2} sm={4} md={4} key={index}>
                        <Item>
                          {val.value} ({val.odd})
                        </Item>
                      </Grid>
                    );
                  })}
                </Grid>
              </AccordionDetails>
            </Accordion>
          );
        })}
    </div>
  );
}
