import axios from "axios";
import React, { useState, useEffect, useContext } from "react";
import { BASE_URL } from "../config";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider(props) {
  const [authUser, setAuthUser] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    async function myAPI() {
      const token = await localStorage.getItem("authToken");
      if (token !== "undefined" && token !== null) {
        await axios
          .post(BASE_URL + "admin/verify_admin", {
            token,
          })
          .then((res) => {
            if (
              res.data.status === 201 &&
              res.data.message === "data_fetched"
            ) {
              setAuthUser(res.data);
              setIsLoggedIn(true);
            }
          })
          .catch((err) => {
            localStorage.removeItem("authToken");
            setAuthUser(null);
            setIsLoggedIn(false);
          });
      }
    }
    myAPI();
  }, []);

  useEffect(() => {
    console.log(authUser,"check")
    if (authUser !== null) {
      if (authUser.data.token !== null) {
        authTokenSave(authUser.token);
      } else {
        localStorage.removeItem("authToken");
        setAuthUser(null);
        setIsLoggedIn(false);
      }
    } else {
      localStorage.removeItem("authToken");
      setAuthUser(null);
      setIsLoggedIn(false);
    }
  }, [authUser]);

  const authTokenSave = async (token) => {
    await localStorage.setItem("authToken", token);
  };

  const value = {
    authUser,
    isLoggedIn,
    setAuthUser,
    setIsLoggedIn,
  };

  return (
    <AuthContext.Provider value={value}>{props.children}</AuthContext.Provider>
  );
}
