import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "fullName", headerName: "Full Name", width: 130 },
  { field: "dob", headerName: "Date of Birth", width: 130 },
  { field: "age", headerName: "Age", width: 130 },
  { field: "kycStatus", headerName: "KYC Status", width: 130 },
  { field: "accountStatus", headerName: "Account Status", width: 130 },
  {
    field: "action",
    headerName: "Action",
    renderCell: (params) => {
      const onClick = (e) => {
        e.stopPropagation(); // don't select this row after clicking

        return alert(JSON.stringify(params.row, null, 4));
      };

      return <Button onClick={onClick}>Update Account</Button>;
    },
    width: 160,
  },
];

const rows = [
  {
    id: 1,
    fullName: "John Samules",
    dob: "10/10/1996",
    age: "26",
    kycStatus: "Pending",
    accountStatus: "Active",
  },
  {
    id: 2,
    fullName: "John Smith",
    dob: "10/10/1996",
    age: "26",
    kycStatus: "Approved",
    accountStatus: "Active",
  },
];

export default function UsersTable() {
  return (
    <div style={{ height: 400, width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[100]}
      />
    </div>
  );
}
