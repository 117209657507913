import {
  Button,
  CircularProgress,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
} from "@mui/material";
import React, { Fragment, useState } from "react";

import RefreshIcon from "@mui/icons-material/Refresh";
import { BASE_URL } from "../../../../config";
import axios from "axios";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

const HandballOddsRow = ({
  index,
  row,
  showBets,
  // updateUserAmount,
  // updateUserWallet,
  // updateUserWalletsLoader,
  // setOpen,
  // open,
}) => {
  const [open, setOpen] = React.useState(false);

  const [updateUserWalletsLoader, setUpdateUserWalletsLoader] = useState({
    loading: false,
    id: null,
  });
  const [loading, setLoading] = useState(false);
  const [pendingBets, setPendingBets] = useState(undefined);

  const getActiveMatchOdds = async () => {
    return axios
      .get(BASE_URL + `admin/Handball/get_active_odds_only/${row.id}`)
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          setPendingBets(res.data.data[0]);
          return true;
        } else {
          setPendingBets(undefined);
        }
      })
      .catch((err) => {
        alert("Something went wrong. Please try again.");
        if (err.response.data.message === "not_found") {
          setPendingBets(undefined);
        } else {
          alert("Something went wrong!");
          setPendingBets(undefined);
        }
      });
  };

  const updateUserAmount = async (row) => {
    setUpdateUserWalletsLoader({ loading: true, id: row.id });
    await axios
      .put(BASE_URL + `admin/handball/update_user_amount`, {
        id: row.id,
        league_id: row.league_id,
        match_id: row.match_id,
      })
      .then(async (res) => {
        if (
          res.status === 200 &&
          res.data.status === 200 &&
          res.data.message === "bets_settled"
        ) {
          setUpdateUserWalletsLoader({ loading: false, id: null });
          setOpen(false);
          alert("User bet details updated successfully.");
          getActiveMatchOdds();
        }
      })
      .catch((err) => {
        setUpdateUserWalletsLoader({ loading: false, id: null });
        setOpen(false);
        alert("Something went wrong! Please try again");
      });
  };

  const updateUserWallet = async (row, columnName) => {
    setUpdateUserWalletsLoader({ loading: true, id: row.id });
    await axios
      .put(BASE_URL + `admin/Handball/update_user_wallet/${columnName}`, {
        id: row.id,
        league_id: row.league_id,
        match_id: row.match_id,
      })
      .then(async (res) => {
        if (
          res.status === 200 &&
          res.data.status === 200 &&
          res.data.message === "bets_settled"
        ) {
          setUpdateUserWalletsLoader({ loading: false, id: null });
          setOpen(false);
          alert("User wallet updated successfully.");
          getActiveMatchOdds();
        }
      })
      .catch((err) => {
        setUpdateUserWalletsLoader({ loading: false, id: null });
        setOpen(false);
        alert("Something went wrong! Please try again");
      });
  };

  const openPanel = async () => {
    if (open) {
      setOpen(false);
    } else {
      setLoading(true);
      await getActiveMatchOdds()
        .then((res) => {
          setOpen(true);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  return (
    <Fragment key={index.toString()}>
      <TableRow
        sx={{
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        <TableCell component="th" scope="row">
          {row.match_id}
        </TableCell>
        <TableCell align="right">{row.match_date}</TableCell>
        <TableCell align="right">{row.league_name}</TableCell>
        <TableCell align="right">{row.home_team.name}</TableCell>
        <TableCell align="right">{row.away_team.name}</TableCell>

        <TableCell align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => !loading && openPanel()}
          >
            {loading ? (
              <CircularProgress />
            ) : open ? (
              <KeyboardArrowUp />
            ) : (
              <KeyboardArrowDown />
            )}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div
              style={{
                background: "#efefef",
              }}
            >
              <div style={{ display: "flex" }}>
                <p
                  style={{
                    paddingLeft: 10,
                    paddingRight: 20,
                    fontWeight: "bold",
                  }}
                >
                  STEP 1:{" "}
                </p>
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  style={{ marginRight: 10, marginTop: 10, marginBottom: 10 }}
                  onClick={() => showBets(row.bets, row)}
                >
                  DECLARE ODDS
                </Button>
              </div>
              <div style={{ display: "flex" }}>
                <p
                  style={{
                    paddingLeft: 10,
                    paddingRight: 20,
                    fontWeight: "bold",
                  }}
                >
                  STEP 2:{" "}
                </p>
                {pendingBets?.player_list !== undefined &&
                  pendingBets.player_list?.length > 0 && (
                    <Button
                      disabled={
                        pendingBets.player_list.length <= 0 ||
                        row.winnings_bets.bets.length <= 0
                      }
                      key={index.toString()}
                      onClick={() =>
                        updateUserWalletsLoader.loading !== true &&
                        updateUserAmount(row)
                      }
                      color="primary"
                      size="small"
                      variant="contained"
                      style={{
                        marginRight: 10,
                        marginTop: 10,
                        marginBottom: 10,
                      }}
                    >
                      {updateUserWalletsLoader.loading &&
                        updateUserWalletsLoader.id === row.id && (
                          <>
                            UPDATING please wait...
                            <CircularProgress
                              color="inherit"
                              style={{
                                width: 20,
                                height: 20,
                                marginLeft: 20,
                              }}
                            />
                          </>
                        )}
                      {updateUserWalletsLoader.id !== row.id && (
                        <>
                          UPDATE USER BETS{" "}
                          <b>
                            &nbsp; ({pendingBets.player_list.length} Users
                            played)
                          </b>
                        </>
                      )}
                    </Button>
                  )}
              </div>
              {/* <div style={{ display: "flex" }}>
                <p
                  style={{
                    paddingLeft: 10,
                    paddingRight: 20,
                    fontWeight: "bold",
                  }}
                >
                  STEP 3:{" "}
                </p>
                {pendingBets !== undefined &&
                  Object.keys(pendingBets).map((item, index) => {
                    return pendingBets[item]?.final_bets_settled !==
                      undefined &&
                      pendingBets[item]?.final_bets_settled.filter(
                        (item) => item.walletUnsettled === true
                      ).length > 0 ? (
                      <Button
                        key={index.toString()}
                        disabled={
                          row.winnings_bets.bets.length <= 0 ||
                          parseInt(
                            pendingBets[item].bets.filter(
                              (item) => item.unsettled === true
                            ).length
                          ) > 0
                            ? true
                            : false ||
                              pendingBets[item]?.final_bets_settled ===
                                undefined ||
                              pendingBets[item]?.final_bets_settled.filter(
                                (item) => item.walletUnsettled === true
                              ).length <= 0
                        }
                        onClick={() =>
                          updateUserWalletsLoader.loading !== true &&
                          updateUserWallet(row, item)
                        }
                        color="primary"
                        size="small"
                        variant="contained"
                        style={{
                          marginRight: 10,
                          marginTop: 10,
                          marginBottom: 10,
                        }}
                      >
                        {updateUserWalletsLoader.loading &&
                          updateUserWalletsLoader.id === row.id && (
                            <>
                              UPDATING please wait...
                              <CircularProgress
                                color="inherit"
                                style={{
                                  width: 20,
                                  height: 20,
                                  marginLeft: 20,
                                }}
                              />
                            </>
                          )}
                        {updateUserWalletsLoader.id !== row.id && (
                          <>
                            UPDATE USER WALLETS (
                            {pendingBets[item]?.final_bets_settled
                              ? pendingBets[item]?.final_bets_settled.filter(
                                  (item) => item.walletUnsettled === true
                                ).length
                              : 0}
                            )
                          </>
                        )}
                      </Button>
                    ) : (
                      false
                    );
                  })}
              </div> */}
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default HandballOddsRow;
