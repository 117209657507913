import React, { useEffect } from "react";
import { useState } from "react";
import Layout from "./components/shared/layout";
import Login from "./pages/auth/Login";
import Dashboard from "./pages/main/Dashboard";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Users from "./pages/main/Users";
import Withdraw from "./pages/main/Withdraw";
import ManageStaff from "./pages/main/ManageStaff";
import BasketballLeagues from "./pages/main/sports/BasketballLeagues";
import BasketballMatches from "./pages/main/sports/BasketballMatches";
import BasketballOdds from "./pages/main/sports/BasketballOdds";
import { useAuth } from "./Contexts/AuthContext";
import Error from "./components/shared/layout/Error";
import BaseballLeagues from "./pages/main/sports/baseball/BaseballLeagues";
import BaseballMatches from "./pages/main/sports/baseball/BaseballMatches";
import BaseballOdds from "./pages/main/sports/baseball/BaseballOdds";
import HandballLeagues from "./pages/main/sports/handball/HandballLeagues";
import HandballMatches from "./pages/main/sports/handball/HandballMatches";
import HandballOdds from "./pages/main/sports/handball/HandballOdds";
import HockeyLeagues from "./pages/main/sports/hockey/HockeyLeagues";
import HockeyMatches from "./pages/main/sports/hockey/HockeyMatches";
import HockeyOdds from "./pages/main/sports/hockey/HockeyOdds";
import RugbyLeagues from "./pages/main/sports/rugby/RugbyLeagues";
import RugbyMatches from "./pages/main/sports/rugby/RugbyMatches";
import RugbyOdds from "./pages/main/sports/rugby/RugbyOdds";
import VolleyballLeagues from "./pages/main/sports/volleyball/VolleyballLeagues";
import VolleyballMatches from "./pages/main/sports/volleyball/VolleyballMatches";
import VolleyballOdds from "./pages/main/sports/volleyball/VolleyballOdds";
import FootballLeagues from "./pages/main/sports/football/FootballLeagues";
import FootballMatches from "./pages/main/sports/football/FootballMatches";
import FootballOdds from "./pages/main/sports/football/FootballOdds";
import UpdateBanners from "./pages/main/UpdateBanners";
const AppRoutes = () => {
  const auth = useAuth();

  return (
    <Router>
      {auth !== undefined &&
        (auth.authUser !== null && auth.isLoggedIn ? (
          <Layout>
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/users" element={<Users />} />
              <Route path="/withdraw_request" element={<Withdraw />} />
              {/* // BASKETBALL */}
              <Route
                path="/manage_basketball_leagues"
                element={<BasketballLeagues />}
              />
              <Route
                path="/manage_basketball_matches"
                element={<BasketballMatches />}
              />
              <Route
                path="/manage_basketball_odds"
                element={<BasketballOdds />}
              />

              {/* //BASEBALL */}
              <Route
                path="/manage_baseball_leagues"
                element={<BaseballLeagues />}
              />
              <Route
                path="/manage_baseball_matches"
                element={<BaseballMatches />}
              />
              <Route path="/manage_baseball_odds" element={<BaseballOdds />} />

              {/* //HANDBALL */}
              <Route
                path="/manage_handball_leagues"
                element={<HandballLeagues />}
              />
              <Route
                path="/manage_handball_matches"
                element={<HandballMatches />}
              />
              <Route path="/manage_handball_odds" element={<HandballOdds />} />

              {/* //HOCKEY */}
              <Route
                path="/manage_hockey_leagues"
                element={<HockeyLeagues />}
              />
              <Route
                path="/manage_hockey_matches"
                element={<HockeyMatches />}
              />
              <Route path="/manage_hockey_odds" element={<HockeyOdds />} />
              {/* //RUGBY */}
              <Route path="/manage_rugby_leagues" element={<RugbyLeagues />} />
              <Route path="/manage_rugby_matches" element={<RugbyMatches />} />
              <Route path="/manage_rugby_odds" element={<RugbyOdds />} />
              {/* //VOLLEYBALL */}
              <Route
                path="/manage_volleyball_leagues"
                element={<VolleyballLeagues />}
              />
              <Route
                path="/manage_volleyball_matches"
                element={<VolleyballMatches />}
              />
              <Route
                path="/manage_volleyball_odds"
                element={<VolleyballOdds />}
              />
              {/* //FOOTBALL */}
              <Route
                path="/manage_football_leagues"
                element={<FootballLeagues />}
              />
              <Route
                path="/manage_football_matches"
                element={<FootballMatches />}
              />
              <Route path="/manage_football_odds" element={<FootballOdds />} />
              <Route path="/update_banners" element={<UpdateBanners />} />
              <Route path="/manage_staff" element={<ManageStaff />} />
              <Route path="*" element={<Error />} />
            </Routes>
          </Layout>
        ) : (
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Login />} />
          </Routes>
        ))}
    </Router>
  );
};

export default AppRoutes;
