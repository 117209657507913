import {
  Alert,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { BASE_URL } from "../../../../config.js";
import HandballMatchesTable from "../../../../components/common/HandballMatchesTable.jsx";

const HandballMatches = () => {
  const [allLeagues, setAllLeagues] = useState(undefined);
  const [selectedLeague, setSelectedLeague] = useState("");
  const [selectedLeagueID, setSelectedLeagueID] = useState("");
  const [allMatches, setAllMatches] = useState(undefined);
  const [alertInfo, setAlerInfo] = useState({
    data: undefined,
    message: "",
    isVisible: false,
  });
  const [snackInfo, setSnackInfo] = useState({
    message: "",
    isVisible: false,
  });

  useEffect(() => {
    getActiveLeagues();
  }, []);

  useEffect(() => {
    if (selectedLeagueID !== "") {
      getMatches();
    }
  }, [selectedLeagueID]);

  const getActiveLeagues = async () => {
    axios
      .post(BASE_URL + "sports/get_active_leagues", {
        sports_category: "handball",
      })
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          setAllLeagues(res.data);
        } else {
          setAllLeagues(undefined);
        }
      })
      .catch((err) => {
        setAllLeagues(undefined);
        setSnackInfo({
          message: "Error while fetching leagues data.",
          isVisible: true,
        });
      });
  };

  const getMatches = async () => {
    axios
      .post(BASE_URL + "admin/handball/get_matches", {
        league_id: selectedLeagueID,
      })
      .then((res) => {
        if (res.data.status === 200 && res.data.message === "data_fetched") {
          setAllMatches(res.data.data);
        } else {
          setAllMatches(undefined);
        }
      })
      .catch((err) => {
        setAllMatches(undefined);
        setSnackInfo({
          message: "Error while fetching matches.",
          isVisible: true,
        });
      });
  };

  return (
    <>
      <Grid container rowSpacing={1}>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <div style={{ height: 400, width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 20,
              }}
            >
              <FormControl style={{ width: 400 }}>
                <InputLabel id="demo-simple-select-label">
                  Select League
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedLeague}
                  label="Select League"
                  onChange={(event) => setSelectedLeague(event.target.value)}
                >
                  {allLeagues !== undefined &&
                    allLeagues?.data.map((res) => {
                      return (
                        <MenuItem
                          key={res.id}
                          value={res.name}
                          onClick={() => setSelectedLeagueID(res.league_id)}
                        >
                          {res.name}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </div>
            <Snackbar
              open={snackInfo.isVisible}
              autoHideDuration={4000}
              message={snackInfo.message}
              onClose={() =>
                setSnackInfo({
                  message: "",
                  isVisible: false,
                })
              }
            />
            <HandballMatchesTable allMatches={allMatches} />
          </div>
        </Grid>
      </Grid>
      <br />
      <br />
      <br />
    </>
  );
};

export default HandballMatches;
