import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../config";
import { DataGrid } from "@mui/x-data-grid";

const UpdateBanners = () => {
  const [sportsType, setSportsType] = useState("Football");
  const [adminData, setAdminData] = useState([]);
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState("");

  const columns = [
    {
      field: "imageName",
      headerName: "NAME",
      width: 300, // renderCell will render the component
    },
    {
      field: "image",
      headerName: "IMAGE",
      width: 300,
      renderCell: (params) => {
        let img = JSON.parse(params.row.image);
        return (
          <img
            src={img.imageData}
            alt=""
            style={{ width: "100%", height: "auto" }}
          />
        );
      },
    },
    {
      field: "action",
      headerName: "ACTION",
      width: 300,
      renderCell: (params) => (
        <Button
          color="inherit"
          size="small"
          variant="outlined"
          style={{ marginRight: 10 }}
          onClick={() => deleteImage(params.row.id)}
        >
          DELETE IMAGE
        </Button>
      ),
    },
  ];

  useEffect(() => {
    getData();
  }, [sportsType]);

  const getData = async () => {
    await axios
      .get(BASE_URL + `admin/get_banners/${sportsType}`)
      .then((res) => {
        if (res.data.status === 201 && res.data.message === "data_fetched.") {
          setAdminData(res.data.data);
        }
      });
  };

  const addNewImage = async () => {
    if (image !== null && imageName !== "" && sportsType !== "") {
      await axios
        .post(BASE_URL + "admin/add_banner", {
          image,
          imageName,
          sportsType: sportsType,
        })
        .then((res) => {
          if (
            res.data.status === 201 &&
            res.data.message === "banner_updated"
          ) {
            alert("Banner Updated successfully.");
            getData();
            setImage(null);
            setImageName("");
          }
        })
        .catch((err) => {
          setImage(null);
          setImageName("");
          alert("Something went wrong. Please try again");
        });
    } else {
      alert("All fields required.");
    }
  };

  const deleteImage = async (id) => {
    await axios
      .delete(BASE_URL + `admin/delete_banner/${id}`)
      .then((res) => {
        if (res.data.status === 200 && res.data.message === "banner_deleted") {
          alert("Banner Deleted successfully.");
          getData();
        }
      })
      .catch((err) => {
        alert("Something went wrong. Please try again");
      });
  };

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].size > 1000000) {
        alert("File size must be less than 1 MB");
      } else {
        setImageName(event.target.files[0].name);
        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = function () {
          setImage(reader.result);
        };
      }
    }
  };
  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      style={{ marginRight: 10, marginLeft: 10 }}
    >
      <Grid style={{ marginLeft: 10, marginTop: 10 }}>
        <Grid container>
          <FormControl style={{ width: 200, marginRight: 10 }}>
            <InputLabel id="demo-simple-select-label">Sports Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={sportsType}
              label="Sports Type"
              onChange={(event) => setSportsType(event.target.value)}
            >
              <MenuItem value={"Football"}>Football</MenuItem>
              <MenuItem value={"Basketball"}>Basketball</MenuItem>
              <MenuItem value={"Baseball"}>Baseball</MenuItem>
              <MenuItem value={"Handball"}>Handball</MenuItem>
              <MenuItem value={"Hockey"}>Hockey</MenuItem>
              <MenuItem value={"Rugby"}>Rugby</MenuItem>
              <MenuItem value={"Volleyball"}>Volleyball</MenuItem>
            </Select>
          </FormControl>
          <div
            style={{
              width: 250,
              background: "#eee",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
              cursor: "pointer",
            }}
          >
            <input
              type="file"
              onChange={onImageChange}
              className="filetype"
              style={{ alignSelf: "center", marginLeft: 20 }}
            />
          </div>
          <Button
            size="small"
            variant="contained"
            style={{ marginLeft: 10, height: "auto" }}
            onClick={() => addNewImage()}
          >
            Add Image
          </Button>
        </Grid>
        <p style={{ color: "red", fontWeight: "bold" }}>
          Image should be less than 1 mb and should be in size(width: 512px and
          height: 284px)
        </p>
        {/* // Table */}
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              sx={{
                // disable cell selection style
                ".MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                // pointer cursor on ALL rows
                "& .MuiDataGrid-row:hover": {
                  cursor: "pointer",
                },
              }}
              rows={adminData}
              rowHeight={150}
              // rows={rows}
              columns={columns}
              // onRowClick={(event) => activateThis(event)}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UpdateBanners;
