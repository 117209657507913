import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Button } from "@mui/material";
import RugbyOddsDrawer from "./RugbyOddsDrawer";

export default function RugbyMatchesTable({ allMatches }) {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [matchDetails, setMatchDetails] = React.useState(undefined);

  const passMatchDetails = (matchDetails) => {
    setMatchDetails(matchDetails);
    setOpenDrawer(true);
  };
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer component={Paper} sx={{ height: "100%" }}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: 100 }}>MATCH ID</TableCell>
              <TableCell sx={{ minWidth: 220 }} align="right">
                START DATE
              </TableCell>
              <TableCell sx={{ minWidth: 200 }} align="right">
                LEAGUE / CUP NAME
              </TableCell>
              <TableCell sx={{ minWidth: 50 }} align="right">
                STATUS
              </TableCell>
              <TableCell sx={{ minWidth: 180 }} align="right">
                HOME TEAM
              </TableCell>
              <TableCell sx={{ minWidth: 180 }} align="right">
                AWAY TEAM
              </TableCell>
              <TableCell sx={{ minWidth: 200 }} align="center">
                ACTION
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {allMatches?.response.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell align="right">{row.date}</TableCell>
                <TableCell align="right">{row.league.name}</TableCell>
                <TableCell align="right">{row.status.long}</TableCell>
                <TableCell align="right">{row.teams.home.name}</TableCell>
                <TableCell align="right">{row.teams.away.name}</TableCell>
                <TableCell align="right">
                  <Button
                    disabled={new Date(row.date) > new Date() ? false : true}
                    color="primary"
                    size="small"
                    variant="contained"
                    style={{ marginRight: 10 }}
                    onClick={() =>
                      new Date(row.date) > new Date() && passMatchDetails(row)
                    }
                  >
                    ACTIVATE ODDS
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <RugbyOddsDrawer
        isOpen={openDrawer}
        setOpenDrawer={setOpenDrawer}
        matchDetails={matchDetails}
      />
    </Paper>
  );
}
