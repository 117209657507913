import { Alert, Button, Grid, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { BASE_URL } from "../../../../config";
import HockeyActiveLeagues from "./HockeyActiveLeagues";

const columns = [
  { field: "id", headerName: "LEAGUE ID", width: 100 },

  {
    field: "logo",
    headerName: "IMAGE",
    width: 300,
    renderCell: (params) => (
      <img src={params.value} style={{ width: "auto", height: "100%" }} />
    ), // renderCell will render the component
  },
  { field: "name", headerName: "LEAGUE / CUP", width: 300 },
  { field: "type", headerName: "TYPE", width: 200 },
];

const HockeyLeagues = () => {
  const [allLeagues, setAllLeagues] = useState(undefined);
  const [alertInfo, setAlerInfo] = useState({
    data: undefined,
    message: "",
    isVisible: false,
  });
  const [snackInfo, setSnackInfo] = useState({
    message: "",
    isVisible: false,
  });
  const [updateActiveLeagues, setUpdateActiveLeagues] = useState(false);

  useEffect(() => {
    axios
      .get(BASE_URL + "admin/hockey/get_leagues")
      .then((res) => {
        if (res.data.status === 200 && res.data.message === "data_fetched") {
          setAllLeagues(res.data.data);
        }
      })
      .catch((err) => {
        if (err.response.data.status === 404) {
          setAllLeagues(undefined);
          setSnackInfo({
            message:
              err.response.data.message === "no_data_exists"
                ? "No league available at this moment. Please try again."
                : "Sport you have selected is not available at the moment.",
            isVisible: true,
          });
        } else {
          setAllLeagues(undefined);
          setSnackInfo({
            message: err.response.data.message,
            isVisible: true,
          });
        }
      });
  }, []);

  const activateThis = (event) => {
    setAlerInfo({
      data: event,
      message: `Click on Activate League to add ${event.row.name} league in a database.`,
      isVisible: true,
    });
  };

  const onactivateLeague = async () => {
    if (alertInfo.data !== undefined) {
      await axios
        .post(BASE_URL + "admin/activate_league", {
          league_id: alertInfo.data.row.id,
          logo_image: alertInfo.data.row.logo,
          name: alertInfo.data.row.name,
          type: alertInfo.data.row.type,
          sports_type: "hockey",
        })
        .then((res) => {
          if (res.data.status === 200) {
            if (res.data.message === "league_activate_successfully") {
              setSnackInfo({
                message: "League activated successfully.",
                isVisible: true,
              });
              setUpdateActiveLeagues(true);
              setAlerInfo({
                data: undefined,
                message: "",
                isVisible: false,
              });
            }
            if (res.data.message === "already_exists") {
              setSnackInfo({
                message: "League already exists.",
                isVisible: true,
              });
              setAlerInfo({
                data: undefined,
                message: "",
                isVisible: false,
              });
            }
          }
        })
        .catch((err) => {
          setSnackInfo({
            message:
              "Something went wrong while adding league. Please try again.",
            isVisible: true,
          });
        });
    } else {
    }
  };
  return (
    <>
      <Snackbar
        open={snackInfo.isVisible}
        autoHideDuration={4000}
        message={snackInfo.message}
        onClose={() =>
          setSnackInfo({
            message: "",
            isVisible: false,
          })
        }
      />
      {alertInfo.isVisible && (
        <Alert
          style={{ marginBottom: 20 }}
          action={
            <>
              <Button
                color="inherit"
                size="small"
                variant="outlined"
                style={{ marginRight: 10 }}
                onClick={() => onactivateLeague()}
              >
                ACTIVATE LEAGUE
              </Button>
              <Button
                color="inherit"
                size="small"
                variant="outlined"
                onClick={() =>
                  setAlerInfo({
                    data: undefined,
                    message: "",
                    isVisible: false,
                  })
                }
              >
                CANCEL
              </Button>
            </>
          }
          variant="filled"
        >
          {alertInfo.message}
        </Alert>
      )}
      <Grid container rowSpacing={1}>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <div style={{ height: 400, width: "100%" }}>
            <DataGrid
              sx={{
                // disable cell selection style
                ".MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                // pointer cursor on ALL rows
                "& .MuiDataGrid-row:hover": {
                  cursor: "pointer",
                },
              }}
              rows={
                allLeagues !== undefined && allLeagues?.response.length > 0
                  ? allLeagues.response
                  : []
              }
              // rows={rows}
              columns={columns}
              onRowClick={(event) => activateThis(event)}
            />
          </div>
        </Grid>

        <Grid item xs={12} style={{ marginTop: 10 }}>
          <HockeyActiveLeagues
            setUpdateActiveLeagues={setUpdateActiveLeagues}
            updateActiveLeagues={updateActiveLeagues}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default HockeyLeagues;
