import { Alert, Button, Grid, Snackbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { BASE_URL } from "../../../../config.js";

const columns = [
  { field: "league_id", headerName: "LEAGUE ID", width: 100 },

  {
    field: "logo",
    headerName: "IMAGE",
    width: 300,
    renderCell: (params) => (
      <img src={params.value} style={{ width: "auto", height: "100%" }} />
    ), // renderCell will render the component
  },
  { field: "name", headerName: "LEAGUE / CUP", width: 300 },
  { field: "type", headerName: "TYPE", width: 200 },
];
const VolleyballActiveLeagues = ({
  setUpdateActiveLeagues,
  updateActiveLeagues,
}) => {
  const [allLeagues, setAllLeagues] = useState(undefined);
  const [alertInfo, setAlerInfo] = useState({
    data: undefined,
    message: "",
    isVisible: false,
  });
  const [snackInfo, setSnackInfo] = useState({
    message: "",
    isVisible: false,
  });

  useEffect(() => {
    getActiveLeagues();
  }, []);

  useEffect(() => {
    if (updateActiveLeagues === true) {
      getActiveLeagues();
      setUpdateActiveLeagues(false);
    }
  }, [updateActiveLeagues]);

  const getActiveLeagues = async () => {
    axios
      .post(BASE_URL + "sports/get_active_leagues", {
        sports_category: "volleyball",
      })
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          setAllLeagues(res.data);
        } else {
          setAllLeagues(undefined);
        }
      })
      .catch((err) => {
        setAllLeagues(undefined);
        setSnackInfo({
          message: "Error while fetching leagues data.",
          isVisible: true,
        });
      });
  };

  const deactivateThis = (event) => {
    setAlerInfo({
      data: event,
      message: `Click on Deactivate League to remove ${event.row.name} league from a database.`,
      isVisible: true,
    });
  };

  const ondeactivateLeague = () => {
    if (alertInfo.data !== undefined) {
      axios
        .post(BASE_URL + "admin/deactivate_league", {
          league_id: alertInfo.data.row.league_id,
          sports_type: "volleyball",
        })
        .then((res) => {
          if (res.data.status === 200) {
            if (res.data.message === "league_deactivated_successfully") {
              setSnackInfo({
                message: "League deleted successfully.",
                isVisible: true,
              });
              setAlerInfo({
                data: undefined,
                message: "",
                isVisible: false,
              });
              getActiveLeagues();
            }
          }
        })
        .catch((err) => {
          setSnackInfo({
            message: "Error while deactivating a league. please try again.",
            isVisible: true,
          });
          setAlerInfo({
            data: undefined,
            message: "",
            isVisible: false,
          });
        });
    }
  };

  return (
    <>
      <Grid container rowSpacing={1}>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <div style={{ height: 400, width: "100%" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h2>Active Leagues</h2>
              </div>
              <div style={{ alignSelf: "end", marginBottom: 10 }}>
                <Button
                  color="primary"
                  size="small"
                  variant="contained"
                  onClick={() => getActiveLeagues()}
                >
                  REFRESH
                </Button>
              </div>
            </div>
            <Snackbar
              open={snackInfo.isVisible}
              autoHideDuration={4000}
              message={snackInfo.message}
              onClose={() =>
                setSnackInfo({
                  message: "",
                  isVisible: false,
                })
              }
            />
            {alertInfo.isVisible && (
              <Alert
                severity="error"
                style={{ marginBottom: 20 }}
                action={
                  <>
                    <Button
                      color="inherit"
                      size="small"
                      variant="outlined"
                      style={{ marginRight: 10 }}
                      onClick={() => ondeactivateLeague()}
                    >
                      DEACTIVATE LEAGUE
                    </Button>
                    <Button
                      color="inherit"
                      size="small"
                      variant="outlined"
                      onClick={() =>
                        setAlerInfo({
                          data: undefined,
                          message: "",
                          isVisible: false,
                        })
                      }
                    >
                      CANCEL
                    </Button>
                  </>
                }
                variant="filled"
              >
                {alertInfo.message}
              </Alert>
            )}

            <DataGrid
              rows={allLeagues !== undefined ? allLeagues?.data : []}
              sx={{
                // disable cell selection style
                ".MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                // pointer cursor on ALL rows
                "& .MuiDataGrid-row:hover": {
                  cursor: "pointer",
                },
              }}
              columns={columns}
              onRowClick={(event) => deactivateThis(event)}
            />
          </div>
        </Grid>
      </Grid>
      <br />
      <br />
      <br />
    </>
  );
};

export default VolleyballActiveLeagues;
