import * as React from "react";
import Drawer from "@mui/material/Drawer";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useEffect } from "react";
import axios from "axios";
import { BASE_URL } from "../../config";
import OddsAccordion from "./OddsAccordion";

export default function HockeyOddsDrawer({
  isOpen,
  setOpenDrawer,
  matchDetails,
}) {
  const [bookmakers, setBookmakers] = React.useState([]);
  const [marketOdds, setMarketOdds] = React.useState([]);
  const [selectedBookmaker, setSelectedBookmaker] = React.useState("");
  useEffect(() => {
    if (isOpen) {
      axios
        .get(BASE_URL + "admin/hockey/get_bookmakers")
        .then((res) => {
          if (res.data.status === 200 && res.data.message === "data_fetched") {
            setBookmakers(res.data.data.response);
          }
        })
        .catch((err) => {
          if (err.response.data.status === 404) {
            setBookmakers([]);
          } else {
            setBookmakers([]);
          }
        });
    }
  }, [isOpen]);

  const getMarketOdds = async (value, id) => {
    setSelectedBookmaker(value);
    await axios
      .post(BASE_URL + "admin/hockey/get_market_odds", {
        league_id: matchDetails.league.id,
        game_id: matchDetails.id,
        bookmaker_id: id,
      })
      .then((res) => {
        if (res.data.status === 200 && res.data.message === "data_fetched") {
          setMarketOdds(res.data.data?.response);
        }
      })
      .catch((err) => {
        if (err.response.data.status === 404) {
          setMarketOdds([]);
        } else {
          setMarketOdds([]);
        }
      });
  };

  const drawerClose = async () => {
    setBookmakers([]);
    setMarketOdds([]);
    setSelectedBookmaker("");
    setOpenDrawer(false);
  };

  const activateMatchOdds = async () => {
    await axios
      .post(BASE_URL + "admin/hockey/activate_market_odds", {
        league_id: marketOdds[0].league.id,
        league_name: marketOdds[0].league.name,
        match_id: marketOdds[0].game.id,
        match_date: new Date(marketOdds[0].game.date),
        home_team: marketOdds[0].game.teams.home,
        away_team: marketOdds[0].game.teams.away,
        bets: { bets: marketOdds[0].bookmakers[0].bets },
      })
      .then((res) => {
        if (
          res.data.status === 200 &&
          res.data.message === "match_odds_activated_successfully"
        ) {
          alert("ODDS Activated Successfully");
          drawerClose();
        }
      })
      .catch((err) => {
        if (err.response.data.status === 403) {
          alert("Bets are already active");
          drawerClose();
        } else {
          alert("Something went wrong. Please try again");
          drawerClose();
        }
      });
  };
  return (
    <div>
      <Drawer anchor={"right"} open={isOpen} onClose={() => drawerClose()}>
        <Grid style={{ width: 400 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: 20,
              marginTop: 20,
              marginLeft: 20,
            }}
          >
            <FormControl style={{ width: 200 }}>
              <InputLabel id="demo-simple-select-label">
                Select Bookmaker
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedBookmaker}
                label="Select Bookmaker"
              >
                {bookmakers?.map((res) => {
                  return (
                    <MenuItem
                      name={res.id}
                      key={res.name}
                      value={res.name}
                      onClick={() => getMarketOdds(res.name, res.id)}
                    >
                      {res.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <Button
              color="primary"
              size="small"
              variant="contained"
              style={{ marginRight: 20, width: 150 }}
              onClick={() => activateMatchOdds()}
            >
              ACTIVATE ODDS
            </Button>
          </div>
        </Grid>
        <Grid style={{ width: 400 }}>
          {marketOdds.length > 0 ? (
            <OddsAccordion marketOdds={marketOdds} />
          ) : (
            <p style={{ textAlign: "center" }}>NO ODDS AVAILABLE</p>
          )}
        </Grid>
      </Drawer>
    </div>
  );
}
