import {
  Button,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../../../config.js";
import styled from "@emotion/styled";
import BaseballOddsRow from "./BaseballOddsRow.jsx";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  padding: 10,
  textAlign: "center",
  color: "#000",
}));

const BaseballOdds = () => {
  // const [open, setOpen] = React.useState(false);
  const [matchOddsData, setMatchOddsData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [oddsData, setOddsData] = useState([]);
  const [matchDetails, setMatchDetails] = useState(undefined);
  const [winningBets, setWinningBets] = useState([]);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showDeclaredOdds, setShowDeclaredOdds] = useState(false);
  // const [updateUserWalletsLoader, setUpdateUserWalletsLoader] = useState({
  //   loading: false,
  //   id: null,
  // });
  useEffect(() => {
    getActiveMatchOdds();
  }, []);

  const getActiveMatchOdds = async () => {
    axios
      .get(BASE_URL + "admin/Baseball/get_active_odds_matches")
      .then((res) => {
        if (res.status === 200 && res.data.status === 200) {
          setMatchOddsData(res.data.data);
        } else {
          setMatchOddsData([]);
        }
      })
      .catch((err) => {
        if (err.response.data.message === "not_found") {
          setMatchOddsData([]);
        } else {
          alert("Something went wrong!");
        }
      });
  };

  const showBets = (rowData, row) => {
    setMatchDetails(row);
    setOddsData(rowData.bets);
    setIsOpen(true);
  };

  const cancelUpdate = () => {
    setOddsData([]);
    setIsOpen(false);
  };

  const selectBetWinner = (betID, betName, betValue, betOdd) => {
    let allBets = winningBets;
    allBets = allBets.filter((bet) => bet.id !== betID);
    allBets.push({
      id: betID,
      name: betName,
      value: betValue,
      odd: betOdd,
    });

    setWinningBets(allBets);
  };

  const submitBets = async () => {
    setShowConfirmation(true);
  };

  const submitBetsAndUpdate = async () => {
    if (oddsData.length !== winningBets.length) {
      alert("All bets are not declared. Please check and declare all bets.");
    } else {
      await axios
        .put(BASE_URL + "admin/Baseball/update_winner_odds", {
          league_id: matchDetails.league_id,
          match_id: matchDetails.match_id,
          bets: winningBets,
        })
        .then(async (res) => {
          if (
            res.status === 200 &&
            res.data.status === 200 &&
            res.data.message === "bets_updated_successfully"
          ) {
            setWinningBets([]);
            setMatchDetails(undefined);
            setShowConfirmation(false);
            setIsOpen(false);
            await getActiveMatchOdds();
            alert("Bets declared successfully");
          }
        })
        .catch((err) => {
          setWinningBets([]);
          setMatchDetails(undefined);
          setShowConfirmation(false);
          setIsOpen(false);
          alert("Something went wrong! Please try again");
        });
    }
  };

  // const updateUserAmount = async (row, columnName) => {
  //   setUpdateUserWalletsLoader({ loading: true, id: row.id });
  //   await axios
  //     .put(BASE_URL + `admin/Baseball/update_user_amount/${columnName}`, {
  //       id: row.id,
  //       league_id: row.league_id,
  //       match_id: row.match_id,
  //     })
  //     .then(async (res) => {
  //       if (
  //         res.status === 200 &&
  //         res.data.status === 200 &&
  //         res.data.message === "bets_settled"
  //       ) {
  //         setUpdateUserWalletsLoader({ loading: false, id: null });
  //         setOpen(false);
  //         alert("User bet details updated successfully.");
  //         getActiveMatchOdds();
  //       }
  //     })
  //     .catch((err) => {
  //       setUpdateUserWalletsLoader({ loading: false, id: null });
  //       setOpen(false);
  //       alert("Something went wrong! Please try again");
  //     });
  // };

  // const updateUserWallet = async (row, columnName) => {
  //   setUpdateUserWalletsLoader({ loading: true, id: row.id });
  //   await axios
  //     .put(BASE_URL + `admin/Baseball/update_user_wallet/${columnName}`, {
  //       id: row.id,
  //       league_id: row.league_id,
  //       match_id: row.match_id,
  //     })
  //     .then(async (res) => {
  //       if (
  //         res.status === 200 &&
  //         res.data.status === 200 &&
  //         res.data.message === "bets_settled"
  //       ) {
  //         setUpdateUserWalletsLoader({ loading: false, id: null });
  //         setOpen(false);
  //         alert("User wallet updated successfully.");
  //         getActiveMatchOdds();
  //       }
  //     })
  //     .catch((err) => {
  //       setUpdateUserWalletsLoader({ loading: false, id: null });
  //       setOpen(false);
  //       alert("Something went wrong! Please try again");
  //     });
  // };

  return (
    <>
      <Grid container rowSpacing={1}>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer component={Paper} sx={{ height: "100%" }}>
              <Table
                stickyHeader
                aria-label="simple table"
                style={{ width: "auto", tableLayout: "auto" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ minWidth: 140 }}>MATCH ID</TableCell>
                    <TableCell sx={{ minWidth: 250 }} align="right">
                      START DATE
                    </TableCell>
                    <TableCell sx={{ minWidth: 250 }} align="right">
                      LEAGUE / CUP NAME
                    </TableCell>

                    <TableCell sx={{ minWidth: 200 }} align="right">
                      HOME TEAM
                    </TableCell>
                    <TableCell sx={{ minWidth: 200 }} align="right">
                      AWAY TEAM
                    </TableCell>
                    <TableCell sx={{ minWidth: 100 }} align="center">
                      ACTION
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {matchOddsData.length > 0 ? (
                    matchOddsData?.map((row, index) => {
                      return (
                        <BaseballOddsRow
                          key={index.toString()}
                          index={index}
                          row={row}
                          showBets={showBets}
                          // updateUserAmount={updateUserAmount}
                          // updateUserWallet={updateUserWallet}
                          // updateUserWalletsLoader={updateUserWalletsLoader}
                          // setOpen={setOpen}
                          // open={open}
                        />
                      );
                    })
                  ) : (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        No active odds available
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
      <br />
      <br />
      <br />
      <Drawer anchor={"bottom"} open={isOpen} onClose={() => setIsOpen(false)}>
        <Grid style={{ margin: 10 }}>
          <Button
            color={!showDeclaredOdds ? "primary" : "inherit"}
            size="small"
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={() => setShowDeclaredOdds(false)}
          >
            UPDATE ODDS
          </Button>
          <Button
            color={showDeclaredOdds ? "primary" : "inherit"}
            size="small"
            variant="contained"
            style={{ marginRight: 10 }}
            onClick={() => setShowDeclaredOdds(true)}
          >
            DECLARED ODDS
          </Button>
        </Grid>
        {!showDeclaredOdds ? (
          <Grid style={{ height: 500 }}>
            {oddsData.length > 0 ? (
              oddsData.map((res) => {
                return (
                  <div
                    style={{
                      background: "#efefef",
                      marginBottom: 10,
                      marginTop: 10,
                      marginRight: 10,
                      marginLeft: 10,
                      padding: 10,
                    }}
                    key={res.id}
                  >
                    <p>{res.name}</p>
                    <RadioGroup
                      row
                      onChange={(event) =>
                        selectBetWinner(
                          res.id,
                          res.name,
                          event.target.value,
                          event.target.name
                        )
                      }
                    >
                      <Grid container spacing={{ xs: 2 }} columns={{ xs: 12 }}>
                        {res.values.map((val, index) => {
                          return (
                            <Grid item xs={2} key={index}>
                              <Item>
                                <FormControlLabel
                                  value={val.value}
                                  control={<Radio name={val.odd} />}
                                  label={val.value + " (" + val.odd + ") "}
                                />
                              </Item>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </RadioGroup>
                  </div>
                );
              })
            ) : (
              <p style={{ textAlign: "center" }}>NO ODDS AVAILABLE</p>
            )}
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                textAlign: "center",
                marginTop: 20,
                marginBottom: 50,
              }}
            >
              <Button
                color="error"
                size="small"
                variant="contained"
                style={{ marginRight: 10, alignSelf: "center" }}
                onClick={() => cancelUpdate()}
              >
                CLOSE & CLEAR
              </Button>
              <Button
                color="primary"
                size="small"
                variant="contained"
                style={{ marginRight: 10, alignSelf: "center" }}
                onClick={() => submitBets()}
              >
                SUBMIT AND DECLARE WINNING ODDS
              </Button>
            </div>
            <br />
          </Grid>
        ) : (
          <Grid style={{ height: 500 }}>
            {matchDetails.winnings_bets?.bets?.length > 0 ? (
              matchDetails.winnings_bets?.bets.map((res) => {
                return (
                  <div
                    style={{
                      background: "#efefef",
                      marginBottom: 10,
                      marginTop: 10,
                      marginRight: 10,
                      marginLeft: 10,
                      padding: 10,
                    }}
                    key={res.id}
                  >
                    <p>{res.name}</p>
                    <Grid container spacing={{ xs: 2 }} columns={{ xs: 12 }}>
                      <Grid item xs={2}>
                        <Item>
                          {res.value} ({res.odd})
                        </Item>
                      </Grid>
                    </Grid>
                  </div>
                );
              })
            ) : (
              <p style={{ textAlign: "center" }}>NO WINNINGS ODDS AVAILABLE</p>
            )}
            <br />
          </Grid>
        )}
      </Drawer>

      {/* // Dialog */}
      <Dialog
        open={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirmation !"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be changed once confirmed. Be careful before
            confirming the bets. If you are not sure you can close this and
            re-check your bets declaration.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirmation(false)}>CLOSE</Button>
          <Button onClick={() => submitBetsAndUpdate()} autoFocus>
            YES I CONFIRM
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BaseballOdds;
