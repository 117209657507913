import React from "react";
import DashboardCard from "../../components/main/dashboardCard/DashboardCard";
import { Grid, Typography } from "@mui/material";

const Dashboard = () => {
  return (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} sm={6} md={4}>
          <DashboardCard title={"TOTAL USERS"} value={"1000"} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DashboardCard title={"PENDING KYC REQUESTS"} value={"1000"} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <DashboardCard title={"WITHDRAW REQUESTS"} value={"1000"} />
        </Grid>
      </Grid>
      <Grid
        container
        rowSpacing={1}
        columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        mt={5}
      ></Grid>
    </>
  );
};

export default Dashboard;
