import {
  Alert,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import { BASE_URL } from "../../../../config";
import FootballActiveLeagues from "./FootballActiveLeagues";

const allCountries = [
  { id: "DE", name: "GERMANY" },
  { id: "CZ", name: "CZECHIA" },
  { id: "PL", name: "POLAND" },
  { id: "NL", name: "NETHERLANDS" },
  { id: "RO", name: "ROMANIA" },
  { id: "HU", name: "HUNGARY" },
  { id: "FR", name: "FRANCE" },
  { id: "LV", name: "LATVIA" },
  { id: "SI", name: "SLOVENIA" },
  { id: "EE", name: "ESTONIA" },
  { id: "BG", name: "BULGARIA" },
  { id: "IT", name: "ITALY" },
  { id: "LT", name: "LITHUANIA" },
  { id: "BE", name: "BELGIUM" },
  { id: "SK", name: "SLOVAKIA" },
  { id: "HR", name: "CROATIA " },
  { id: "UA", name: "UKRAINE " },
  { id: "LU", name: "LUXEMBOURG" },
  { id: "ES", name: "SPAIN" },
  { id: "GR", name: "GREECE" },
  { id: "FI", name: "FINLAND" },
  { id: "AT", name: "AUSTRIA " },
  { id: "DK", name: "DENMARK " },
  { id: "PT", name: "PORTUGAL " },
  { id: "GB", name: "UNITED KINGDOM" },
  { id: "RU", name: "RUSSIA" },
  { id: "SE", name: "SWEDEN" },
  { id: "BY", name: "BELARUS" },
  { id: "RS", name: "SERBIA" },
  { id: "CH", name: "SWITZERLAND" },
  { id: "SE", name: "SWEDEN" },
  { id: "NO", name: "NORWAY" },
  { id: "IE", name: "IRELAND" },
  { id: "NO", name: "NORWAY" },
  { id: "MD", name: "MOLDOVA" },
  { id: "BA", name: "BOSNIA AND HERZEGOVINA" },
  { id: "AL", name: "ALBANIA" },
  { id: "LT", name: "LITHUANIA" },
  { id: "MK", name: "NORTH MACEDONIA" },
  { id: "XK", name: "KOSOVO" },
  { id: "ME", name: "MONTENEGRO" },
  { id: "MT", name: "MALTA" },
  { id: "IS", name: "ICELAND" },
  { id: "AD", name: "ANDORRA" },
  { id: "MC", name: "MONACO" },
  { id: "LI", name: "LIECHTENSTEIN" },
  { id: "SM", name: "SAN MARINO" },
];
const columns = [
  { field: "id", headerName: "LEAGUE ID", width: 100 },

  {
    field: "logo",
    headerName: "IMAGE",
    width: 300,
    renderCell: (params) => (
      <img src={params.value} style={{ width: "auto", height: "100%" }} />
    ), // renderCell will render the component
  },
  { field: "name", headerName: "LEAGUE / CUP", width: 300 },
  { field: "type", headerName: "TYPE", width: 200 },
];

const FootballLeagues = () => {
  const [allLeagues, setAllLeagues] = useState(undefined);
  const [alertInfo, setAlerInfo] = useState({
    data: undefined,
    message: "",
    isVisible: false,
  });
  const [snackInfo, setSnackInfo] = useState({
    message: "",
    isVisible: false,
  });
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCountryID, setSelectedCountryID] = useState("");
  const [updateActiveLeagues, setUpdateActiveLeagues] = useState(false);

  useEffect(() => {
    if (selectedCountryID !== "") {
      axios
        .get(BASE_URL + `admin/football/get_leagues/${selectedCountryID}`)
        .then((res) => {
          if (res.data.status === 200 && res.data.message === "data_fetched") {
            let filteredData = [];
            res.data.data.response.map((item) => {
              filteredData.push(item.league);
            });
            setAllLeagues(filteredData);
          }
        })
        .catch((err) => {
          if (err.response.data.status === 404) {
            setAllLeagues(undefined);
            setSnackInfo({
              message:
                err.response.data.message === "no_data_exists"
                  ? "No league available at this moment. Please try again."
                  : "Sport you have selected is not available at the moment.",
              isVisible: true,
            });
          } else {
            setAllLeagues(undefined);
            setSnackInfo({
              message: err.response.data.message,
              isVisible: true,
            });
          }
        });
    }
  }, [selectedCountryID]);

  const activateThis = (event) => {
    setAlerInfo({
      data: event,
      message: `Click on Activate League to add ${event.row.name} league in a database.`,
      isVisible: true,
    });
  };

  const onactivateLeague = async () => {
    if (alertInfo.data !== undefined) {
      await axios
        .post(BASE_URL + "admin/activate_league", {
          league_id: alertInfo.data.row.id,
          logo_image: alertInfo.data.row.logo,
          name: alertInfo.data.row.name,
          type: alertInfo.data.row.type,
          sports_type: "football",
        })
        .then((res) => {
          if (res.data.status === 200) {
            if (res.data.message === "league_activate_successfully") {
              setSnackInfo({
                message: "League activated successfully.",
                isVisible: true,
              });
              setUpdateActiveLeagues(true);
              setAlerInfo({
                data: undefined,
                message: "",
                isVisible: false,
              });
            }
            if (res.data.message === "already_exists") {
              setSnackInfo({
                message: "League already exists.",
                isVisible: true,
              });
              setAlerInfo({
                data: undefined,
                message: "",
                isVisible: false,
              });
            }
          }
        })
        .catch((err) => {
          setSnackInfo({
            message:
              "Something went wrong while adding league. Please try again.",
            isVisible: true,
          });
        });
    } else {
    }
  };
  return (
    <>
      <Snackbar
        open={snackInfo.isVisible}
        autoHideDuration={4000}
        message={snackInfo.message}
        onClose={() =>
          setSnackInfo({
            message: "",
            isVisible: false,
          })
        }
      />
      {alertInfo.isVisible && (
        <Alert
          style={{ marginBottom: 20 }}
          action={
            <>
              <Button
                color="inherit"
                size="small"
                variant="outlined"
                style={{ marginRight: 10 }}
                onClick={() => onactivateLeague()}
              >
                ACTIVATE LEAGUE
              </Button>
              <Button
                color="inherit"
                size="small"
                variant="outlined"
                onClick={() =>
                  setAlerInfo({
                    data: undefined,
                    message: "",
                    isVisible: false,
                  })
                }
              >
                CANCEL
              </Button>
            </>
          }
          variant="filled"
        >
          {alertInfo.message}
        </Alert>
      )}
      <Grid container rowSpacing={1}>
        <Grid item xs={12} style={{ marginTop: 10 }}>
          <FormControl style={{ width: 400 }}>
            <InputLabel id="demo-simple-select-label">
              Select Country
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedCountry}
              label="Select Country"
              onChange={(event) => setSelectedCountry(event.target.value)}
            >
              {allCountries !== undefined &&
                allCountries?.map((res, index) => {
                  return (
                    <MenuItem
                      key={index.toString()}
                      value={res.name}
                      onClick={() => setSelectedCountryID(res.id)}
                    >
                      {res.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <div style={{ height: 400, width: "100%", marginTop: 10 }}>
            <DataGrid
              sx={{
                // disable cell selection style
                ".MuiDataGrid-cell:focus": {
                  outline: "none",
                },
                // pointer cursor on ALL rows
                "& .MuiDataGrid-row:hover": {
                  cursor: "pointer",
                },
              }}
              rows={
                allLeagues !== undefined && allLeagues?.length > 0
                  ? allLeagues
                  : []
              }
              // rows={rows}
              columns={columns}
              onRowClick={(event) => activateThis(event)}
            />
          </div>
        </Grid>

        <Grid item xs={12} style={{ marginTop: 10 }}>
          <FootballActiveLeagues
            setUpdateActiveLeagues={setUpdateActiveLeagues}
            updateActiveLeagues={updateActiveLeagues}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default FootballLeagues;
