import { Grid } from "@mui/material";
import React from "react";
import WithdrawRequestTable from "../../components/main/WithdrawRequestTable/WithdrawRequestTable";

const Withdraw = () => {
  return (
    <Grid
      container
      rowSpacing={1}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      style={{ marginRight: 10, marginLeft: 10 }}
    >
      <WithdrawRequestTable />
    </Grid>
  );
};

export default Withdraw;
