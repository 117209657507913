import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import GroupIcon from "@mui/icons-material/Group";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LogoutIcon from "@mui/icons-material/Logout";
import { Link, useLocation } from "react-router-dom";
import {
  ArrowForwardIos,
  ExpandLess,
  ExpandMore,
  SportsBasketball,
} from "@mui/icons-material";
import { Collapse } from "@mui/material";
import { useAuth } from "../../../Contexts/AuthContext";
const drawerWidth = 300;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function PersistentDrawerLeft({ children }) {
  const location = useLocation();
  const auth = useAuth();

  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [openBasketballMenu, setOpenBasketballMenu] = React.useState(false);
  const [openBaseballMenu, setOpenBaseballMenu] = React.useState(false);
  const [openHandballMenu, setOpenHandballMenu] = React.useState(false);
  const [openHockeyMenu, setOpenHockeyMenu] = React.useState(false);
  const [openRugbyMenu, setOpenRugbyMenu] = React.useState(false);
  const [openVolleyballMenu, setOpenVolleyballMenu] = React.useState(false);
  const [openFootballMenu, setOpenFootballMenu] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(true);
  };

  const logout = () => {
    localStorage.removeItem("authToken");
    auth.setAuthUser(null);
    auth.setIsLoggedIn(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <p style={{ textTransform: "uppercase" }}>
            {location.pathname === "/"
              ? "Dashboard"
              : location.pathname === "/manage_basketball_leagues"
              ? "Basketball Leagues"
              : location.pathname === "/manage_basketball_matches"
              ? "Basketball Matches"
              : location.pathname === "/manage_basketball_odds"
              ? "Basketball Odds"
              : location.pathname === "/manage_baseball_leagues"
              ? "baseball Leagues"
              : location.pathname === "/manage_baseball_matches"
              ? "baseball Matches"
              : location.pathname === "/manage_baseball_odds"
              ? "baseball Odds"
              : location.pathname === "/manage_handball_leagues"
              ? "handball Leagues"
              : location.pathname === "/manage_handball_matches"
              ? "handball Matches"
              : location.pathname === "/manage_handball_odds"
              ? "handball Odds"
              : location.pathname === "/manage_hockey_leagues"
              ? "hockey Leagues"
              : location.pathname === "/manage_hockey_matches"
              ? "hockey Matches"
              : location.pathname === "/manage_hockey_odds"
              ? "hockey Odds"
              : location.pathname === "/manage_rugby_leagues"
              ? "rugby Leagues"
              : location.pathname === "/manage_rugby_matches"
              ? "rugby Matches"
              : location.pathname === "/manage_rugby_odds"
              ? "rugby Odds"
              : location.pathname === "/manage_volleyball_leagues"
              ? "volleyball Leagues"
              : location.pathname === "/manage_volleyball_matches"
              ? "volleyball Matches"
              : location.pathname === "/manage_volleyball_odds"
              ? "volleyball Odds"
              : location.pathname === "/manage_football_leagues"
              ? "football Leagues"
              : location.pathname === "/manage_football_matches"
              ? "football Matches"
              : location.pathname === "/manage_football_odds"
              ? "football Odds"
              : location.pathname === "/bets_manage_upcoming"
              ? "Manage upcoming bets"
              : location.pathname === "/bets_manage_live"
              ? "Manage live bets"
              : location.pathname === "/users"
              ? "Users"
              : location.pathname === "/update_banner"
              ? "Update Banner"
              : location.pathname === "/withdraw_request"
              ? "Withdraw Requests"
              : location.pathname === "/manage_staff"
              ? "ADD NEW ADMIN"
              : ""}
          </p>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader style={{ justifyContent: "center" }}>
          <h2 style={{ textAlign: "center" }}>SPORTIFY</h2>
          {/* <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton> */}
        </DrawerHeader>
        <Divider />
        <List>
          <Link to="/" style={{ color: "#000", textDecoration: "none" }}>
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary={"Dashboard"} />
              </ListItemButton>
            </ListItem>
          </Link>
          {/* // BASKETBALL */}
          <ListItem
            disablePadding
            onClick={() => setOpenBasketballMenu(!openBasketballMenu)}
          >
            <ListItemButton>
              <ListItemIcon>
                <SportsBasketball />
              </ListItemIcon>
              <ListItemText primary={"Basketball Section"} />
              {openBasketballMenu ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse
            in={openBasketballMenu}
            timeout="auto"
            unmountOnExit
            style={{ marginLeft: 30 }}
          >
            <Link
              to="/manage_basketball_leagues"
              style={{ color: "#000", textDecoration: "none" }}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ArrowForwardIos fontSize="10" />
                  </ListItemIcon>
                  <ListItemText primary={"Basketball Leagues"} />
                </ListItemButton>
              </ListItem>
            </Link>

            <Link
              to="/manage_basketball_matches"
              style={{ color: "#000", textDecoration: "none" }}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ArrowForwardIos fontSize="10" />
                  </ListItemIcon>
                  <ListItemText primary={"Basketball Matches"} />
                </ListItemButton>
              </ListItem>
            </Link>

            <Link
              to="/manage_basketball_odds"
              style={{ color: "#000", textDecoration: "none" }}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ArrowForwardIos fontSize="10" />
                  </ListItemIcon>
                  <ListItemText primary={"Basketball ODDS"} />
                </ListItemButton>
              </ListItem>
            </Link>
          </Collapse>
          {/* // BASEBALL */}
          <ListItem
            disablePadding
            onClick={() => setOpenBaseballMenu(!openBaseballMenu)}
          >
            <ListItemButton>
              <ListItemIcon>
                <SportsBasketball />
              </ListItemIcon>
              <ListItemText primary={"Baseball Section"} />
              {openBaseballMenu ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse
            in={openBaseballMenu}
            timeout="auto"
            unmountOnExit
            style={{ marginLeft: 30 }}
          >
            <Link
              to="/manage_baseball_leagues"
              style={{ color: "#000", textDecoration: "none" }}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ArrowForwardIos fontSize="10" />
                  </ListItemIcon>
                  <ListItemText primary={"Baseball Leagues"} />
                </ListItemButton>
              </ListItem>
            </Link>

            <Link
              to="/manage_baseball_matches"
              style={{ color: "#000", textDecoration: "none" }}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ArrowForwardIos fontSize="10" />
                  </ListItemIcon>
                  <ListItemText primary={"Baseball Matches"} />
                </ListItemButton>
              </ListItem>
            </Link>

            <Link
              to="/manage_baseball_odds"
              style={{ color: "#000", textDecoration: "none" }}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ArrowForwardIos fontSize="10" />
                  </ListItemIcon>
                  <ListItemText primary={"Baseball ODDS"} />
                </ListItemButton>
              </ListItem>
            </Link>
          </Collapse>
          {/* // HANDBALL */}
          <ListItem
            disablePadding
            onClick={() => setOpenHandballMenu(!openHandballMenu)}
          >
            <ListItemButton>
              <ListItemIcon>
                <SportsBasketball />
              </ListItemIcon>
              <ListItemText primary={"Handball Section"} />
              {openHandballMenu ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse
            in={openHandballMenu}
            timeout="auto"
            unmountOnExit
            style={{ marginLeft: 30 }}
          >
            <Link
              to="/manage_handball_leagues"
              style={{ color: "#000", textDecoration: "none" }}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ArrowForwardIos fontSize="10" />
                  </ListItemIcon>
                  <ListItemText primary={"Handball Leagues"} />
                </ListItemButton>
              </ListItem>
            </Link>

            <Link
              to="/manage_handball_matches"
              style={{ color: "#000", textDecoration: "none" }}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ArrowForwardIos fontSize="10" />
                  </ListItemIcon>
                  <ListItemText primary={"Handball Matches"} />
                </ListItemButton>
              </ListItem>
            </Link>

            <Link
              to="/manage_handball_odds"
              style={{ color: "#000", textDecoration: "none" }}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ArrowForwardIos fontSize="10" />
                  </ListItemIcon>
                  <ListItemText primary={"Handball ODDS"} />
                </ListItemButton>
              </ListItem>
            </Link>
          </Collapse>
          {/* // HOCKEY */}
          <ListItem
            disablePadding
            onClick={() => setOpenHockeyMenu(!openHockeyMenu)}
          >
            <ListItemButton>
              <ListItemIcon>
                <SportsBasketball />
              </ListItemIcon>
              <ListItemText primary={"Hockey Section"} />
              {openHockeyMenu ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse
            in={openHockeyMenu}
            timeout="auto"
            unmountOnExit
            style={{ marginLeft: 30 }}
          >
            <Link
              to="/manage_hockey_leagues"
              style={{ color: "#000", textDecoration: "none" }}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ArrowForwardIos fontSize="10" />
                  </ListItemIcon>
                  <ListItemText primary={"Hockey Leagues"} />
                </ListItemButton>
              </ListItem>
            </Link>

            <Link
              to="/manage_hockey_matches"
              style={{ color: "#000", textDecoration: "none" }}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ArrowForwardIos fontSize="10" />
                  </ListItemIcon>
                  <ListItemText primary={"Hockey Matches"} />
                </ListItemButton>
              </ListItem>
            </Link>

            <Link
              to="/manage_hockey_odds"
              style={{ color: "#000", textDecoration: "none" }}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ArrowForwardIos fontSize="10" />
                  </ListItemIcon>
                  <ListItemText primary={"Hockey ODDS"} />
                </ListItemButton>
              </ListItem>
            </Link>
          </Collapse>
          {/* // RUGBY */}
          <ListItem
            disablePadding
            onClick={() => setOpenRugbyMenu(!openRugbyMenu)}
          >
            <ListItemButton>
              <ListItemIcon>
                <SportsBasketball />
              </ListItemIcon>
              <ListItemText primary={"Rugby Section"} />
              {openRugbyMenu ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse
            in={openRugbyMenu}
            timeout="auto"
            unmountOnExit
            style={{ marginLeft: 30 }}
          >
            <Link
              to="/manage_rugby_leagues"
              style={{ color: "#000", textDecoration: "none" }}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ArrowForwardIos fontSize="10" />
                  </ListItemIcon>
                  <ListItemText primary={"Rugby Leagues"} />
                </ListItemButton>
              </ListItem>
            </Link>

            <Link
              to="/manage_rugby_matches"
              style={{ color: "#000", textDecoration: "none" }}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ArrowForwardIos fontSize="10" />
                  </ListItemIcon>
                  <ListItemText primary={"Rugby Matches"} />
                </ListItemButton>
              </ListItem>
            </Link>

            <Link
              to="/manage_rugby_odds"
              style={{ color: "#000", textDecoration: "none" }}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ArrowForwardIos fontSize="10" />
                  </ListItemIcon>
                  <ListItemText primary={"Rugby ODDS"} />
                </ListItemButton>
              </ListItem>
            </Link>
          </Collapse>
          {/* // Volleyball */}
          <ListItem
            disablePadding
            onClick={() => setOpenVolleyballMenu(!openVolleyballMenu)}
          >
            <ListItemButton>
              <ListItemIcon>
                <SportsBasketball />
              </ListItemIcon>
              <ListItemText primary={"Volleyball Section"} />
              {openVolleyballMenu ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse
            in={openVolleyballMenu}
            timeout="auto"
            unmountOnExit
            style={{ marginLeft: 30 }}
          >
            <Link
              to="/manage_volleyball_leagues"
              style={{ color: "#000", textDecoration: "none" }}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ArrowForwardIos fontSize="10" />
                  </ListItemIcon>
                  <ListItemText primary={"Volleyball Leagues"} />
                </ListItemButton>
              </ListItem>
            </Link>

            <Link
              to="/manage_volleyball_matches"
              style={{ color: "#000", textDecoration: "none" }}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ArrowForwardIos fontSize="10" />
                  </ListItemIcon>
                  <ListItemText primary={"Volleyball Matches"} />
                </ListItemButton>
              </ListItem>
            </Link>

            <Link
              to="/manage_volleyball_odds"
              style={{ color: "#000", textDecoration: "none" }}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ArrowForwardIos fontSize="10" />
                  </ListItemIcon>
                  <ListItemText primary={"Volleyball ODDS"} />
                </ListItemButton>
              </ListItem>
            </Link>
          </Collapse>
          {/* // Football */}
          <ListItem
            disablePadding
            onClick={() => setOpenFootballMenu(!openFootballMenu)}
          >
            <ListItemButton>
              <ListItemIcon>
                <SportsBasketball />
              </ListItemIcon>
              <ListItemText primary={"Football Section"} />
              {openFootballMenu ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
          </ListItem>
          <Collapse
            in={openFootballMenu}
            timeout="auto"
            unmountOnExit
            style={{ marginLeft: 30 }}
          >
            <Link
              to="/manage_football_leagues"
              style={{ color: "#000", textDecoration: "none" }}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ArrowForwardIos fontSize="10" />
                  </ListItemIcon>
                  <ListItemText primary={"Football Leagues"} />
                </ListItemButton>
              </ListItem>
            </Link>

            <Link
              to="/manage_football_matches"
              style={{ color: "#000", textDecoration: "none" }}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ArrowForwardIos fontSize="10" />
                  </ListItemIcon>
                  <ListItemText primary={"Football Matches"} />
                </ListItemButton>
              </ListItem>
            </Link>

            <Link
              to="/manage_football_odds"
              style={{ color: "#000", textDecoration: "none" }}
            >
              <ListItem disablePadding>
                <ListItemButton>
                  <ListItemIcon>
                    <ArrowForwardIos fontSize="10" />
                  </ListItemIcon>
                  <ListItemText primary={"Football ODDS"} />
                </ListItemButton>
              </ListItem>
            </Link>
          </Collapse>
          <Link
            to="/update_banners"
            style={{ color: "#000", textDecoration: "none" }}
          >
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary={"Update Banners"} />
              </ListItemButton>
            </ListItem>
          </Link>
          <Link
            to="/withdraw_request"
            style={{ color: "#000", textDecoration: "none" }}
          >
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <CurrencyExchangeIcon />
                </ListItemIcon>
                <ListItemText primary={"Withdraw Requests"} />
              </ListItemButton>
            </ListItem>
          </Link>
          {/* <Link
            to="/withdraw_request"
            style={{ color: "#000", textDecoration: "none" }}
          >
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <CurrencyExchangeIcon />
                </ListItemIcon>
                <ListItemText primary={"Transactions"} />
              </ListItemButton>
            </ListItem>
          </Link> */}
          <Link
            to="/manage_staff"
            style={{ color: "#000", textDecoration: "none" }}
          >
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <GroupIcon />
                </ListItemIcon>
                <ListItemText primary={"Admin Management"} />
              </ListItemButton>
            </ListItem>
          </Link>
          <Link
            style={{ color: "#000", textDecoration: "none" }}
            onClick={() => logout()}
          >
            <ListItem disablePadding>
              <ListItemButton>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={"Logout"} />
              </ListItemButton>
            </ListItem>
          </Link>
        </List>
        <Divider />
      </Drawer>
      <Main open={open}>
        <DrawerHeader />

        {children}
      </Main>
    </Box>
  );
}
